import React from 'react';
import { cn } from '@/lib/utils';
import { useTranslation } from '@/lib/i18n';
import { Button, Text } from '..';

const NotFound = () => {
    const { t } = useTranslation();
    return (
        <div className="relative bg-gray-50">
            <div
                className={cn([
                    'absolute',
                    'inset-0',
                    "bg-[url('/img/water.png')]",
                    'bg-cover',
                    'opacity-30',
                    'bg-no-repeat',
                ])}
            />
            <div className="relative mx-4 my-auto flex h-screen flex-col items-center justify-center gap-1 text-center">
                <Text className="text-8xl font-bold leading-none md:text-[150px] lg:text-[200px]">404</Text>
                <Text.H2 className="text-4xl lg:text-[3.375rem]">{t('not_found.title')}</Text.H2>
                <Text.Body className="max-w-md">{t('not_found.description')}</Text.Body>
                <div className="mx-5 flex w-full justify-center">
                    <Button href="/" type="button" className="mt-4 w-full lg:w-fit">
                        {t('not_found.cta')}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
